import { Link } from "react-router-dom";
import { DataTableViewProps } from "../../types/components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function DataTableView({
  propertyPaginationList,
  debitsData,
}: DataTableViewProps) {
  function getDebitsDetailsData(id: number) {
    return debitsData?.find((data: any) => data.id === id);
  }

  return (
    <>
      <div
        className="border"
        style={{
          borderRadius: "12px",
          backgroundColor: "#F4F4F4",
          fontFamily: "Poppins",
        }}
      >
        <table
          className="min-w-full divide-y divide-gray-200"
          style={{ borderRadius: "12px" }}
        >
          
              <thead style={{ backgroundColor: "#E4ECE5" }}>
                <tr>
                  <th
                    style={{ color: "black", fontSize: "0.65rem", height: "70px" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    ID
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Name
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Proponent
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Project Type
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Country
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Region
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Carbon Debits Available <span className="lowercase">[kg]</span>
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Carbon Debits Spent <span className="lowercase">[kg]</span>
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Carbon Debits Generated <span className="lowercase">[kg]</span>
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Carbon Seq. Per Year <span className="lowercase">[kg]</span>
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Plot Size <span className="lowercase">[<span>m<sup>2</sup></span>]</span>
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Contract Signed
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    Start Date
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
                  >
                    End Date
                  </th>
                  <th
                    style={{ color: "black", fontSize: "0.65rem" }}
                    className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[300px]"
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {propertyPaginationList?.length > 0 ? (
                  propertyPaginationList.map((listing) => (
                    <tr key={listing.id} style={{ height: "80px" }}>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {listing.id}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {listing.name}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {listing.proponent}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {listing.type}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {listing.country}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {listing.region}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {new Intl.NumberFormat().format(getDebitsDetailsData(Number(listing.id))?.debitsAvailable?.toFixed(0))}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {new Intl.NumberFormat().format(getDebitsDetailsData(Number(listing.id))?.debitsSpent?.toFixed(0))}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {new Intl.NumberFormat().format(getDebitsDetailsData(Number(listing.id))?.debitsGenerated?.toFixed(0))}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {new Intl.NumberFormat().format(listing.carbonDebitsPerYear as any)}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {Number(listing?.plotSize)}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {dayjs.utc(listing?.registrationDate).format('YYYY-MM-DD')}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {dayjs.utc(listing?.startDate).format('YYYY-MM-DD')}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <span
                          style={{ fontSize: "0.65rem" }}
                          className="leading-5 text-gray-900"
                        >
                          {dayjs.utc(listing?.endDate).format('YYYY-MM-DD')}
                        </span>
                      </td>
                      <td className="text-center whitespace-no-wrap text-blue-500">
                        <Link
                          to={`/property-detail/${listing.id}`}
                          style={{
                            backgroundColor: "#247147",
                            borderRadius: "10px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                          className="px-2 py-1 text-white rounded hover:bg-blue-600 focus:outline-none"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ border: "none" }}>
                    <td
                      colSpan={12}
                      style={{
                        backgroundColor: "#F4F4F4",
                        height: "380px",
                        borderRadius: "12px",
                        border: "none",
                        fontFamily: "Poppins",
                        fontSize: "32px",
                        color: "#CCC8C8",
                      }}
                      className="px-6 text-center"
                    >
                      <span className="text-gray-500">Empty</span>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DataTableView;
