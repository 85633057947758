import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";

const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  color: "black",
  height: "72px",
  fontSize: 24,
  fontFamily: "Poppins",
  paddingInline: 100,
  lineHeight: "72px",
  borderBottom: "1px solid grey",
  backgroundColor: "rgb(241, 241, 241)",
};

function Headers1() {
  return (
    <Layout>
      <Header style={headerStyle}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="../../logo.png"
            style={{ margin: "0", padding: "0", width: "44px", height: "42px" }}
            alt="logo"
          />
          <span
            style={{
              paddingLeft: "10px",
              color: "#116C20",
              fontSize: "24px",
              fontFamily: "Poppins",
              fontWeight: "600",
            }}
          >
            Global Carbon Standard
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            top: "18px",
            right: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link
            to={`/`}
            className="bg-[#247147] hover:bg-[#247147B0]"
            style={{
              display: "flex",
              fontFamily: "Poppins",
              alignItems: "center",
              textAlign: "center",
              color: "white",
              height: "36px",
              fontSize: "20px",
              borderRadius: "10px",
            }}
          >
            <p style={{ fontFamily: "Poppins" }} className="px-12">
              HOME
            </p>
          </Link>
        </div>
      </Header>
    </Layout>
  );
}

export default Headers1;
