import exp from "node:constants";

export interface ListingParams {
  _page: number;
  _limit: number;
  _sort?: string;
  _order?: string;
  id?: string;
  proponent?: string;
  name?: string;
  type?: string;
  country?: string;
  region?: string;
  isPublish?: boolean;
}

export interface File {
  filename: string;
  path: string;
  uid: string;
  size: number;
  type: string;
  date: Date;
}

export interface PropertyList {
  [x: string]: any;
  _id?: string;
  id?: number;
  name?: string;
  address?: string;
  imageUrl?: File[];
  proponent?: string;
  type?: string;
  check?: File[];
  version?: File[];
  sign?: File[];
  initial?: File[];
  final?: File[];
  country?: string;
  region?: string;
  plotSize?: number;
  systemSize?: number;
  registrationDate?: Date;
  startDate?: Date;
  endDate?: Date;
  plantTime?: Date;
  carbonDebitsSpent?: number;
  carbonDebitsPerYear?: number;
  carbonDebitsGenerated?: number;
  carbonDebitsAvailable?: number;
  batchNumber?: number;
  initialSupply?: number;
  batchMintLimit?: number;
  totalSupply?: number;
  isPublish?: boolean;
  initialTokenId?: number;
  excludeFromWorker?: boolean;
}

export interface Transactions {
  _id: string,
  carbonCertificateId: number;
  carbonCertificateImage: string;
  aCO2Id: number;
  treeId: number;
  projectId: number;
  date: Date;
  amount: number,
  geoLocation: number[];
  txHash: String;
  batchAddress: String;
}

export interface TransactionResponse {
  data: any
  headers?: {
    get(header: string): string | null;
  };
}

export interface TransactionResponseData {
  post: Transactions[];
  totalNum: number;
}


export interface TransactionParams {
  _page: number;
  _limit: number;
  _sort?: string;
  _order?: string;
  id?: string;
  projectId?: string;
  treeId?: string;
  aCO2Id?: string;
  carbonCertificateId?: string;
}

export interface PropertyListResponse {
  data: any;
  headers?: {
    get(header: string): string | null;
  };
}

// export const URL: string = "https://13.59.219.172:443"
export const EP_URL: string = process.env.REACT_APP_EP_URI || "http://localhost:5000";
