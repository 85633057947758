import { useEffect, useState } from "react";
import { ListingParams, PropertyList, EP_URL } from "../api/apiTypes";
import { getPropertyListings } from "../api/api";
import DataTableView from "../components/tableView/tableView";
import Pagination from "../components/pagination";
import TableSearch from "../components/tableView/tableSearch";
import TableNavSearch from "../components/tableView/tableNavSearch";
import Headers from "../components/headers";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Index() {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [gcs, setGcs] = useState<any>();
  const [carbon, setCarbon] = useState<any>();
  const [carbonOffset, setCarbonOffset] = useState<any>();
  const [total, setTotal] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const [debitsData, setDebitsData] = useState<
    {
      id: String;
      debitsGenerated: string;
      debitsSpent: string;
      debitsAvailable: string;
    }[]
  >();
  const [allProjectsAvailableCo2, setAllProjectsAvailableCo2] =
    useState<number>(0);
  const nav = useNavigate();

  const [propertyPaginationList, setPropertyPaginationList] = useState<
    PropertyList[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<ListingParams>({
    _page: parseInt(sessionStorage.getItem('page-index') ?? '1'),
    _limit: 6,
    _sort: "",
    _order: "",
    id: "",
    proponent: "",
    name: "",
    type: "",
    country: "",
    region: "",
    isPublish: true,
  });

  async function fetchSortedPaginatedListings() {
    const sortedPaginatedListings = await getPropertyListings(params);
    setPropertyPaginationList(sortedPaginatedListings?.data?.post);
    const totalPageNum = sortedPaginatedListings?.data?.totalNum
      ? Math.ceil(sortedPaginatedListings?.data?.totalNum / params._limit)
      : 1;
    setTotalPages(totalPageNum);
  }

  async function prepareCalculatedData(sortedPropertyList: any) {
    const calculatedData: any[] = [];

    for (const property of sortedPropertyList) {
      const spent = Number(property.carbonDebitsSpent) || 0;
      const available = (Number(property.carbonDebitsGenerated) || 0) - spent;
      const debitsData = {
        id: property.id,
        debitsGenerated: property.carbonDebitsGenerated,
        debitsSpent: spent,
        debitsAvailable: available,
      };
      calculatedData.push(debitsData);
    }
    setDebitsData(calculatedData);
  }

  async function fetchData() {
    try {
      const sortedPaginatedListings = await getPropertyListings(params);
      await prepareCalculatedData(sortedPaginatedListings?.data?.post);
      setPropertyPaginationList(sortedPaginatedListings?.data?.post);
      const totalPageNum = Math.ceil(
        sortedPaginatedListings?.data?.totalNum / params._limit,
      );
      setTotalPages(totalPageNum || 1);

      const response = await axios.get(`${EP_URL}/api/post/getTotalCount`);

      setGcs(response.data.totalProjects || 0);
      setCarbon(response.data.totalCarbon || 0);
      setCarbonOffset(response.data.totalCO2offset || 0);
      setAllProjectsAvailableCo2(response.data.totalAvailableCO2 || 0);
      setTotal(response.data.totalLandSize || 0);
      setUnit(0); // TODO: If needed sth else than m2, backend should tell
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  useEffect(() => {
    const fetchDataBeforeRender = async () => {
      setLoading(true);
      await fetchSortedPaginatedListings();
      await fetchData();
      setLoading(false);
    };
    fetchDataBeforeRender();
  }, [params]);

  return (
    <div style={{ backgroundColor: "#E4F8E4" }} className="font-poppins">
      <Headers></Headers>
      <div className="big">
        <div style={{ paddingTop: "90px", zIndex: "1" }}>
          <div style={{ width: "100%" }} className="flex pt-4">
            <div>
              <p
                style={{
                  color: "#71857A",
                  fontSize: "36px",
                  fontStyle: "normal",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                Welcome to the
              </p>
              <p
                style={{
                  color: "#475E51",
                  fontSize: "48px",
                  fontStyle: "normal",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                {" "}
                Global Carbon Registry
              </p>
              <p
                style={{
                  marginTop: "12px",
                  color: "#4E4C4C",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                Your one-stop resource for verified projects operating under the
                Global Carbon
                <br />
                Standard(GCS) and in-depth technical documentation. You can find
                concise
                <br />
                information on verified projects.
              </p>
              <a
                href="https://globalcarbonstandard.org"
                style={{
                  marginLeft: "-10px",
                  marginTop: "36px",
                  display: "flex",
                  fontFamily: "Poppins",
                  alignItems: "center",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#247147",
                  width: "165px",
                  height: "36px",
                  fontSize: "16px",
                  borderRadius: "33px",
                }}
              >
                <span style={{ paddingLeft: "37px" }}>Learn more</span>
              </a>
            </div>
          </div>
        </div>
        <img
          style={{
            position: "absolute",
            right: "70px",
            top: "50px",
            width: "576px",
          }}
          src="../../rect.png"
          alt="rectangle"
        ></img>

        <img
          style={{ position: "absolute", right: "550px", bottom: "120px" }}
          src="../../roof.png"
          alt="roof"
        ></img>
      </div>

      <div className="flex justify-between gap-[20px] px-[100px]">
        <div className="front-page-widget">
          <div className="text-white text-[32px] text-center">{gcs}</div>
          <div className="text-white text-base text-center mt-2">
            GCS Projects Registered
          </div>
        </div>
        <div className="front-page-widget">
          <div className="text-white text-[32px] text-center">
            {new Intl.NumberFormat().format(
              allProjectsAvailableCo2?.toFixed(1) as any,
            )}{" "}
            kg
          </div>
          <div className="text-white text-base text-center mt-2">
            Available CO<sup>2</sup>
          </div>
        </div>
        <div className="front-page-widget">
          <div className="text-white text-[32px] text-center">
            {new Intl.NumberFormat().format(carbon?.toFixed(1))} kg
          </div>
          <div className="text-white text-base text-center mt-2">
            Carbon Debits Issued
          </div>
        </div>
        <div className="front-page-widget cursor-pointer"
          onClick={() => nav("/co-trans/")}
        >
          <div className="text-white text-[32px] text-center">
            {new Intl.NumberFormat().format(carbonOffset?.toFixed(1))} kg
          </div>
          <div className="text-white text-base text-center mt-2">
            CO<sup>2</sup> offset
          </div>
        </div>
        <div className="front-page-widget">
          <div className="text-white text-[32px] text-center">
            {new Intl.NumberFormat().format(total)}&nbsp;
            {unit === 0 && (
              <span>
                m<sup>2</sup>
              </span>
            )}
            {unit === 1 && (
              <span>
                km<sup>2</sup>
              </span>
            )}
            {unit === 2 && <span>ha</span>}
          </div>
          <div className="text-white text-base text-center mt-2">
            Total Land Reforested
          </div>
        </div>
      </div>
      <p
        className="text-poppins"
        style={{
          marginTop: "60px",
          marginBottom: "60px",
          textAlign: "center",
          fontSize: "40px",
          color: "#475E51",
          fontWeight: "600",
          fontFamily: "Poppins",
        }}
      >
        All projects are listed below
      </p>
      <div className="flex text-green-900 text-md justify-center px-6 h-4">
        {loading && <span>Loading...</span>}
      </div>
      <div className="flex justify-between mb-2 mx-6 pb-12">
        <div className="r-search mr-6">
          <TableSearch params={params} setParams={setParams} />
        </div>
        <div className="py-3 pl-3">
          <DataTableView
            propertyPaginationList={propertyPaginationList}
            params={params}
            setParams={setParams}
            debitsData={debitsData}
          />
          <Pagination
            className="mt-2"
            totalPages={totalPages === 0 ? 1 : totalPages}
            onPageChange={(v) => sessionStorage.setItem('page-index', String(v))}
            _page={params._page}
            params={params}
            setParams={setParams}
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
