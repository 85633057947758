import React, {useEffect, useState} from "react";
import {useParams, Link, useNavigate} from "react-router-dom";
import {getPropertyDetails} from "../api/api";
import {PropertyList, EP_URL} from "../api/apiTypes";
import Headers from "../components/headers1";
import {CloseOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import ProjectView from "../components/project/projectView";

interface DataType {
  name: string;
  date: string;
}

function PropertyDetail() {
  const {id} = useParams();
  const [property, setProperty] = useState<PropertyList>();

  useEffect(() => {
    async function fetchPropertyDetails() {
      if (id) {
        const propertyDetails = await getPropertyDetails(Number(id));
        if (propertyDetails) setProperty(propertyDetails?.post);
      }
    }

    fetchPropertyDetails();
  }, [id]);


  if (!property) {
    return <div>Loading...</div>;
  }


  return (
    <div className={'bg-[#F4F4F4] pb-5'}>
      <Headers></Headers>

      <div className="mx-6 md:mx-auto max-w-[1400px]">
        <div className="mt-6 text-right">
          <Link
            to="/"
            className="px-4 py-2 text-black rounded focus:outline-none"
          >
            <CloseOutlined/>
          </Link>
        </div>
        <div className="py-6">
          <p className="text-2xl font-bold text-[#247147]">
            Project Summary {!property.isPublish && "[Draft]"}
          </p>
        </div>

        <ProjectView property={property} adminOnly={false}/>
      </div>
    </div>
  );
}

export default PropertyDetail;
